<template>
  <div style="height: 100%; background-color: #FFFFFF">
    <head-layout
      :head-title="this.formType == 'edit' ? $t('cip.plat.sys.defaultConfig.title.editHeadTitle') : ( this.formType == 'view' ? $t('cip.plat.sys.defaultConfig.title.viewHeadTitle') : $t('cip.plat.sys.defaultConfig.title.addHeadTitle'))"
      :head-btn-options="headBtnOptions"
      @head-save="headSave(false)"
      @head-saveBack="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <form-layout
      ref="formLayout"
      :column="formColumn"
      :dataForm.sync="dataForm"
    ></form-layout>
  </div>
</template>
<script>
import {getDetail, submit} from "@/api/system/defaultConfig";
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {dictionaryBiz} from "@/api/safe";

export default {
  components: {
    HeadLayout,
    FormLayout,
  },
  data() {
    return {
      formType: '',
      themeColor: {},
      dataForm: {
        themeColors: "",
      },
    }
  },
  computed: {
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.formType)) {
        result.push(
          {
            label: "保存",
            emit: "head-save",
            type: "button",
            btnOptType: "save",
          }
        );
        result.push(
          {
            label: "保存并返回",
            emit: "head-saveBack",
            type: "button",
            btnOptType: "saveBack",
          }
        );
      }
      result.push(
        {
          label: "取消",
          emit: "head-cancel",
          type: "button",
          btnOptType: "cancel",
        }
      );
      return result;
    },
    formColumn() {
      return [
        {
          label: this.$t('cip.plat.sys.defaultConfig.field.configName'),
          prop: "configName",
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          disabled: ['view'].includes(this.formType),
          placeholder: this.$t('cip.cmn.rule.inputWarning') + this.$t("cip.plat.sys.defaultConfig.field.configName"),
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.sys.defaultConfig.field.configName'),
            trigger: ["blur", "change"]
          }],
        },
        {
          label: this.$t('cip.plat.sys.defaultConfig.field.navigateMode'),
          prop: "navigateMode",
          type: "select",
          span: 8,
          disabled: ['view'].includes(this.formType),
          dataType: 'String',
          value: "functional",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=navigate_mode",
          placeholder: this.$t('cip.cmn.rule.selectWarning') + this.$t("cip.plat.sys.defaultConfig.field.navigateMode"),
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.sys.defaultConfig.field.navigateMode'),
            trigger: "change"
          }],
        },
        {
          label: this.$t("cip.plat.sys.defaultConfig.field.colorBlock"),
          prop: "colorBlock",
          type: "select",
          span: 8,
          disabled: ['view'].includes(this.formType),
          dataType: 'String',
          value: "dark",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=color_block",
          placeholder: this.$t('cip.cmn.rule.selectWarning') + this.$t("cip.plat.sys.defaultConfig.field.colorBlock"),
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.sys.defaultConfig.field.colorBlock'),
            trigger: "change"
          }],
        },
        {
          label: this.$t("cip.plat.sys.defaultConfig.field.themeClass"),
          prop: "themeClass",
          type: "select",
          dataType: 'String',
          span: 8,
          disabled: ['view'].includes(this.formType),
          value: "blue",
          props: {
            label: "dictValue",
            value: "dictValue"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=theme_color",
          change: this.getDictionaryBiz,
          placeholder: this.$t('cip.cmn.rule.selectWarning') + this.$t("cip.plat.sys.defaultConfig.field.themeClass"),
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.sys.defaultConfig.field.themeClass'),
            trigger: "change"
          }],
        },
        {
          label: this.$t("cip.plat.sys.defaultConfig.field.multiTab"),
          prop: "multiTab",
          type: "select",
          dataType: 'String',
          value: "true",
          span: 8,
          disabled: ['view'].includes(this.formType),
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=is_true",
          placeholder: this.$t('cip.cmn.rule.selectWarning') + this.$t("cip.plat.sys.defaultConfig.field.multiTab"),
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.sys.defaultConfig.field.multiTab'),
            trigger: "change"
          }],
        },
        {
          label: this.$t("cip.plat.sys.defaultConfig.field.searchBox"),
          prop: "searchBox",
          type: "select",
          value: "true",
          dataType: 'String',
          span: 8,
          disabled: ['view'].includes(this.formType),
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=is_true",
          placeholder: this.$t('cip.cmn.rule.selectWarning') + this.$t("cip.plat.sys.defaultConfig.field.searchBox"),
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.sys.defaultConfig.field.searchBox'),
            trigger: "change"
          }],
        },
        {
          label: this.$t("cip.plat.sys.defaultConfig.field.tableStyle"),
          prop: "tableStyle",
          type: "select",
          dataType: 'String',
          value: "false",
          span: 8,
          row: true,
          disabled: ['view'].includes(this.formType),
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=is_true",
          placeholder: this.$t('cip.cmn.rule.selectWarning') + this.$t("cip.plat.sys.defaultConfig.field.tableStyle"),
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.sys.defaultConfig.field.tableStyle'),
            trigger: "change"
          }],
        },
        {
          label: this.$t(`cip.plat.sys.defaultConfig.field.remark`),
          prop: "remark",
          type: "textarea",
          span: 16,
          row: true,
          maxlength: 200,
          showWordLimit: true,
          disabled: ['view'].includes(this.formType),
          placeholder: this.$t(`cip.plat.sys.defaultConfig.field.remark`),
          rules: [{
            required: false,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.sys.knowledge.field.remark'),
            trigger: "change"
          }],
        },
      ]
    }
  },
  created() {
    let {id, type} = this.$route.query;
    this.formType = type;
    if (['view', 'edit'].includes(this.formType)) {
      this.dataForm.id = id
      this.initData()
    }
    this.getDictionaryBiz();
  },
  methods: {
    initData() {
      getDetail(this.dataForm.id).then(res => {
        const {data} = res.data;
        this.dataForm = data;
      })
    },
    // 提交默认设置
    headSave(boolean) {
      this.$refs.formLayout.$refs.form.validate((valid) => {
        if (valid) {
          this.$loading();
          submit(this.dataForm).then((res) => {
            if (res.data.code == 200) {
              if (boolean) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                //刷新数据
                Object.assign(this.dataForm, res.data.data)
                this.dataForm = res.data.data
                this.initData();
                this.$refs.formLayout.$refs.form.allDisabled = false;
                this.type = 'edit';
              }
              this.$message({
                message: "保存成功",
                type: "success",
              });
            }
          }).finally(() => {
            this.$loading().close();
            this.$refs.formLayout.$refs.form.allDisabled = false;
          })
        }
      });
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // 获取数据字典
    getDictionaryBiz() {
      dictionaryBiz("theme_color").then((res) => {
        this.themeColor = res.data.data;
        this.themeColor = this.themeColor.filter(o => o.dictValue === this.dataForm.themeClass)
        if (this.themeColor.length === 1) {
          this.dataForm.themeColors = this.themeColor[0].dictKey;
          this.dataForm.themeClass = this.themeColor[0].dictValue;
          console.log(this.dataForm)
        } else {
          // 业务字典有问题
          this.dataForm.themeClass = "";
        }
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.formBox {
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

.formTitleBox {
  width: calc(100% - 24px);
  border-bottom: 1px solid #bbbbbb;
  padding: 12px;
  display: flex;
  justify-content: space-between;
}

.leftBack {
  cursor: pointer;
  display: flex;
}

.backBox {
  position: relative;
  line-height: 28px;
}

.backBox:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 16px;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #dcdfe6;
}

.rightBtn {
  display: flex;
}

.formContentBox {
  padding: 12px;
  height: calc(100% - 76px);
}

.formMain {
  width: 100%;
  overflow: auto;
  height: 100%;
}

.leftClass {
  width: calc(15% - 48px);
  border: 1px solid #bbbbbb;
  border-radius: 4px;
  padding: 12px 24px;
}

.classDataBox {
  height: calc(100% - 34px);
  overflow: auto;
}

.classLittle {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 14px);
  border: 1px solid #bbbbbb;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 4px;
  margin: 12px 0;
  cursor: pointer;
  font-size: 15px;
  padding: 0 6px;
}

.rightTable {
  width: 84%;
}

.classActive {
  background-color: #E9EDF3;
}

.container {
  display: flex;
  justify-content: space-between;
}

.formTopic {
  width: 100%;
  height: 40px;
  line-height: 40px;
  //border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}
</style>
